import React from 'react';
import PropTypes from 'prop-types';

const formatPoem = (text) =>
  text.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));

const Poem = ({ title, author, location, age, text }) => (
  <div>
    <h3 className="font-bold text-2xl">{title}</h3>
    <div className="mt-8">{formatPoem(text)}</div>
    <div className="mt-8 flex flex-col">
      <div className="text-xs italic">{author}</div>
      <div className="text-xs italic">{location}</div>
      <div className="text-xs italic">{age}</div>
    </div>
  </div>
);

Poem.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  location: PropTypes.string,
  age: PropTypes.number,
  text: PropTypes.string,
};

export default Poem;
