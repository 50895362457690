import React, { useState } from 'react';

import styled from '@emotion/styled';

import Layout from '~/components/layout';
import SEO from '~/components/seo';

import RVLogo from '~/images/rv.svg';
import Shapes1 from '~/images/shapes1.png';
import Shapes2 from '~/images/shapes2.png';

import Image from '~/components/image';
import usePoetryData from '~/hooks/usePoetryData';
import Poem from '~/components/poem';

const PoemContainer = styled.div`
  border-radius: 1rem;
`;
const Art = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 60%;
  left: -60px;
  z-index: 1;
`;

const pickRandomFrom = (items, current) => {
  const options = items.filter((item) => item !== current);
  return options[Math.floor(Math.random() * options.length)];
};

const IndexPage = () => {
  const { poems } = usePoetryData();
  const [poem, setPoem] = useState(pickRandomFrom(poems));
  const randomize = () => setPoem(pickRandomFrom(poems, poem));
  return (
    <Layout>
      <SEO title="Rhymes with Virus" />

      <div className="mt-12 mx-8 md:mx-12">
        <div className="flex flex-col md:flex-row items-center md:items-start">
          <div className="flex-1">
            <div className="flex">
              <div className="pt-4">
                <h1 className="font-extrabold text-3xl md:text-4xl leading-snug">
                  rhymes
                  <br /> with
                  <br /> virus
                </h1>
              </div>
              <div className="ml-4 flex-1">
                <img src={RVLogo} alt="RV" className="h-full object-contain" />
              </div>
            </div>
            <h2 className="font-bold text-2xl md:text-3xl mt-8">
              Pandemic poetry by everyday people
            </h2>
            <div className="mt-8">
              <div>
                A place to create, write, grieve, express, laugh, and share
                during COVID-19.
              </div>
              <div className="mt-8 italic">
                There was a virus called COVID-19
                <br />
                Inspiring us all to be oh so quite clean
                <br />
                To make bread of banana
                <br />
                Send masks to Louisiana
                <br />
                And to write poetry from quarantine.
              </div>
            </div>
            <div className="flex flex-col xs:flex-row items-start mt-8">
              <div className="pt-8 max-w-xs">
                <img
                  src={Shapes1}
                  alt="Shapes and arrow"
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="md:ml-4 xs:pt-4 md:pt-8 self-end xs:self-start order-first xs:order-none my-8 xs:my-0">
                <a
                  href="https://airtable.com/shrCgrf8CXziJMLVD"
                  className="button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  submit a poem
                </a>
              </div>
            </div>
          </div>
          <div className="pt-12 md:ml-16 max-w-xs w-full">
            <div className="relative">
              <Art>
                <img
                  src={Shapes2}
                  alt="Shapes"
                  className="h-full w-full object-contain"
                />
              </Art>
              <Image />
            </div>
          </div>
        </div>
        <div className="my-12">
          <h1 className="text-center text-x-turquoise font-extrabold text-4xl">
            there's no such thing as bad poetry
          </h1>
        </div>
        <div className="mt-12 flex flex-col items-center">
          <PoemContainer className="p-4 max-w-md w-full border-x-purple border-4">
            <Poem
              title={poem.title}
              author={poem.author}
              location={poem.location}
              age={poem.age}
              text={poem.text}
            />
          </PoemContainer>
          <div className="my-8">
            <button type="button" onClick={randomize}>
              randomize
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
