import { useStaticQuery, graphql } from 'gatsby';

const usePoetryData = () => {
  const { dataYaml: data } = useStaticQuery(
    graphql`
      query PoemData {
        dataYaml {
          poems {
            title
            author
            location
            age
            text
          }
        }
      }
    `
  );
  return data;
};

export default usePoetryData;
